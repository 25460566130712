import React from 'react';
import { useParams } from "react-router-dom";
import {IImage} from '../../../models/IImage';
import { DZIViewer } from '../../DZI/DZIViewer/DZIViewer';
import { RouteComponentProps } from "react-router";


const querystring = require('querystring');

interface IDZIViewerContainerProps extends RouteComponentProps {
};


interface IDZIViewerContainerProps {
}

let styles = require('./DZIViewerContainer.module.css');

export function DZIViewerContainer( props: IDZIViewerContainerProps) { 

    const values = querystring.parse(props.location.search);
    // If a token has been passed in the querystring store it in a cookie
    if (values["?token"] != null && values["refresh_token"] != null) {
        var accessToken = values["?token"];
        var refreshToken = values["refresh_token"];
        localStorage.setItem('aims3d.authorizationData', JSON.stringify({ token: accessToken, refreshToken: refreshToken }));
        props.history.push({
            pathname: props.location.pathname,
          })
    }
    
   
    //The point of this container is to have a simple dzi viewer without needing to retrieve full image data and having a connection to the main vaa page

    let { id } :any = useParams();

    let image: IImage = {
        id: id,
        campaignName: '',
        campaignId: 0,
        sessionName:'',
        feeder:'',
        status:'',
        lat:0,
        lon:0,
        thumbnailUrl:'',
        created: new Date(),
        updated: new Date(),
        poleId: '',
        segmentId: '',
        pack: '',
        fileName:'',
        assigneeEmailAddress:'',
        assigneeFirstName:'',
        assigneeSurname:'',
        active: false,
        defectCount: 0,
    }

    return (
        <div className={styles.container}>
            <DZIViewer 
                organisationUsers={[]}
                image={image}
                imageStatuses={[]}
                viewerState={null}
                userPermissions={[]}
                defects={[]}
                isExpanded={false}
                getViewerStateFlag={0}
                onGetViewerState={(dZIViewerState: any) => { }}
                onDefectsChanged={(defects: any[]) => { }}
                isDefectModeEnabled={false}
                isTagModeEnabled={false}
                onDefectModeChanged={(isEnabled: boolean) => { }}
                onTagModeChanged={(isEnabled: boolean) => { }}
                onSelectedDZIImageChanged={(selectedImage: IImage | null) => { }}
                actionPerformed={null}
                updatedImageIds={[] }
                onDefectChanged={() => { }}
                canViewDefects={false}
                imageDefectCount={[]}
            />
        </div>
    );
}
