import React, { useState, useEffect } from 'react';
import { Tooltip, AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core/';
import { makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import { ISegment } from '../../models/ISegment';

import styles from "./SegmentDetailsBasic.module.css";

interface ISegmentDetailsBasicProp {
    segment: ISegment|null;
    enableUrlViewing: boolean;
    onClose: Function;
    onSegmentUrlClick: Function;
}

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div style={{padding:0}} role="tabpanel" hidden={value !== index} id={`wrapped-tabpanel-${index}`} aria-labelledby={`wrapped-tab-${index}`} {...other}>
        {value === index && (
          <Box style={{padding:0}} p={2}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: any) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
        boxShadow: 'none',
        marginTop: 10,
        background: 'transparent !important',
    },
    tabStyle: {
        textTransform:'none',
        minWidth: '50%',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.25,
        paddingRight: 0,
        paddingLeft: 0,
    }
  });

// This is a cut down version of SegmentDetails that is for use with the ThreeDViewer component.  This does not popup and does not support images.

export function SegmentDetailsBasic(props: ISegmentDetailsBasicProp) {
    const [activeTab, setActiveTab] = React.useState('attributes');
    const classes = useStyles();

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setActiveTab(newValue);
      };
    
    let attributesElement: JSX.Element[] = [];
    if (props.segment) {
        var attributes = JSON.parse(props.segment.attributes);
        attributesElement.push(
            <GridList cols={1} style={{paddingTop:13}}>
                {Object.keys(attributes).map((key) =>
                    <GridListTile key={key} cols={1} className={styles.attributes}>
                        <Grid container>
                            <Grid item xs={6}  >{key.toLowerCase()}</Grid>
                            <Grid item xs={6} style={{ fontSize: 12, paddingRight: 5 }} >{attributes[key]}</Grid>
                        </Grid>
                    </GridListTile>
                )}
            </GridList>
        );
    }

    return <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Grid container justify="space-between" >
                        <Grid item>
                            <span style={{ fontWeight: 500 }}> Pole {props.segment && props.segment.externalReferenceId}</span>
                        </Grid>

                        <Grid item>
                            { props.enableUrlViewing && props.segment != null && props.segment.url != null &&
                                <Tooltip title={'Open asset url in viewer'} >
                                    <IconButton style={{padding: '0px 0px 0px 3px'}} disableRipple={true} disableFocusRipple={true} onClick={() => { if ( props.segment != null ) props.onSegmentUrlClick(props.segment.url) }}  >
                                        <LaunchIcon className={styles.clear} />
                                    </IconButton>
                                </Tooltip>
                            }
                            <IconButton style={{paddingLeft:3}}  disableRipple={true} disableFocusRipple={true} onClick={() => props.onClose()}  >
                                <ClearIcon className={styles.clear} />
                            </IconButton>
                        </Grid>

                    </Grid>

                    <div style={{ marginTop: -4, textAlign: 'left', fontSize: 12, fontWeight: 500 }}>
                        <span>{props.segment && props.segment.lat.toFixed(5)}, {props.segment && props.segment.lon.toFixed(5)}</span>
                    </div>
                </div>

                <div className={classes.root}>
                    <AppBar position="static" className={classes.appBar}>
                        <Tabs value={activeTab} className={classes.appBar} onChange={handleTabChange} variant="fullWidth" style={{width:'215px', fontSize:14, backgroundColor:'transparent'}}>
                            <Tab style={{ alignItems:'start !important'}} className={classes.tabStyle} value="attributes" label="Attributes" {...a11yProps('attributes')} />
                            <Tab  className={classes.tabStyle} value="images" {...a11yProps('images')} disabled={true}/>
                        </Tabs>
                    </AppBar>
                    <TabPanel  value={activeTab} index="attributes">
                        <div className={styles.attributesContainer}>
                            {attributesElement}
                        </div>
                    </TabPanel>
                </div>
                
            </div>
        </div>
        ;
}


